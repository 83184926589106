import 'bootstrap';
import jQuery from 'jquery';
import './scss/app.scss';
import './scss/main.scss';
import './scss/main-responsive.scss';

import Swiper from 'swiper';
$ = jQuery;
$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

let debounce = function (func, wait) {
    // we need to save these in the closure
    let timeout, args, context, timestamp;

    return function () {

        // save details of latest call
        context = this;
        args = [].slice.call(arguments, 0);
        timestamp = new Date();

        // this is where the magic happens
        let later = function () {

            // how long ago was the last call
            let last = (new Date()) - timestamp;

            // if the latest call was less that the wait period ago
            // then we reset the timeout to wait for the difference
            if (last < wait) {
                timeout = setTimeout(later, wait - last);

                // or if not we can null out the timer and run the latest
            } else {
                timeout = null;
                func.apply(context, args);
            }
        };

        // we only need to set the timer now if one isn't already running
        if (!timeout) {
            timeout = setTimeout(later, wait);
        }
    }
};

//Navigation
$(function () {
    const $navigation = $('.navbar-menu');
    const $body = $('body');
    const $trigger = $('[data-toggle="navbar-menu"]');

    let a, b;

    function toggleMenu(e) {
        e.preventDefault();

        if (!$body.hasClass('menu-is-open')) {
            clearTimeout(b);

            $body.addClass('menu-is-open');
            $navigation.css('display', 'block');

            a = setTimeout(function () {
                $navigation.addClass('menu-open');
            }, 50);
        } else {
            $body.removeClass('menu-is-open');
            $navigation.removeClass('menu-open');

            b = setTimeout(function () {
                $navigation.css('display', '');
            }, 400);
        }
    }

    $trigger.bind('click', toggleMenu);
});

// Animate when in view
$(function () {
    let element = $('.img-loader, #section-problem-statement h2, #section-problem-statement p, #section-problem-statement .btn, .section-heading h1, .section-heading h2, .section-heading p, .cases-item, .brands-item, .swiper-slide-content h1, .swiper-slide-content p, .swiper-slide-content .btn, .team-item, .timeline-block h2, .timeline-block h3, .timeline-block p, #hero-about h1, #hero-about p'),
        updatePage,
        animateElements;

    updatePage = function () {
        window.requestAnimationFrame(function () {
            animateElements();
        });
    };

    animateElements = function () {
        $('section').each(function () {
            $(this).find(element).each(function (i) {
                let $this = $(this),
                    delay = $this.attr('data-delay');

                if ($this.isInViewport()) {
                    setTimeout(function () {
                        $this.addClass('loaded');
                        setTimeout(function () {
                            if ($this.hasClass('img-loader')) {
                                $this.find('img').css('width', '');
                            }
                        }, 1000);
                    }, 75 * i);
                }
            });
        });
    };

    setInterval(updatePage, 10);
});

//Navbar scrolled
$(function(){
    var header = $('header'),
        w = $(window);

    function headerScroll() {
        // if (w.scrollTop() >= 100) {
        //     header.find('.navbar .container-fluid').css({
        //         opacity: '0'
        //     });
        // } else {
        //     header.find('.navbar .container-fluid').css({
        //         opacity: ''
        //     });
        // }

        if (w.scrollTop() >= 150) {
            header.find('.navbar .container-fluid').css({
                opacity: ''
            });
            header.addClass('header-scrolled');
        } else {
            header.removeClass('header-scrolled');
        }
    }

    w.on('scroll', headerScroll);
});

//Transform <img> to "background-image"
$(function () {
    let $item = $('[data-bg="true"]');

    $item.each(function () {
        let $this = $(this);

        $this.parent().css({
            'background-image': 'url("' + $this.attr('src') + '")'
        });
    });
});

//Swiper - Home
$(function () {
    const swiperWrapper = $('.swiper-home-wrapper');
    var swiper;

    var interleaveOffset = 1;
    var autoplay = 1000;
    var swiperSpeed = 800;

    var elem = document.getElementById("swiper-progress");
    var autoplayTime = autoplay / 156.0674285888672;

    if (swiperWrapper.length) {
        swiper = new Swiper('.swiper-home', {
            speed: swiperSpeed,
            autoplay: {
                delay: autoplay
            },
            noSwipingClass: 'swiper-home',
            watchSlidesProgress: true,
            navigation: {
                nextEl: '.swiper-home-wrapper .swiper-btn-next',
                prevEl: '.swiper-home-wrapper .swiper-btn-prev',
            },
            on: {
                progress: function () {
                    var swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        var slideProgress = swiper.slides[i].progress;
                        var innerOffset = swiper.width * interleaveOffset;
                        var innerTranslate = slideProgress * innerOffset;
                        swiper.slides[i].querySelector(".swiper-slide-transition").style.transform = "translate3d(" + innerTranslate + "px, 0, 0)";
                        swiper.slides[i].querySelector(".swiper-slide-thumb").style.transform = "scale(" + (0.1 + (1 + (slideProgress * 0.1))) + ")";

                        move();
                    }
                },
                touchStart: function () {
                    var swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = "";
                    }
                },
                setTransition: function (speed) {
                    var swiper = this;
                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = speed + "ms";
                        swiper.slides[i].querySelector(".swiper-slide-transition").style.transition = speed + "ms";
                        swiper.slides[i].querySelector(".swiper-slide-transition").style.transitionTimingFunction = "ease";
                        swiper.slides[i].querySelector(".swiper-slide-thumb").style.transitionTimingFunction = "cubic-bezier(0.86, 0, 0.07, 1)";
                        swiper.slides[i].querySelector(".swiper-slide-thumb").style.transition = speed + "ms";
                    }
                },
                transitionStart: function () {
                    $('.swiper-progress-count').text((swiper.realIndex + 1) + '/' + swiper.slides.length);
                }
            }
        });
    }

    setTimeout(function () {
        autoplayTime = (autoplay + swiperSpeed) / 156.0674285888672;
    }, swiperSpeed);

    function move() {
        var length = 0.01;
        var id = setInterval(frame, autoplayTime);

        function frame() {
            if (length >= 156.0674285888672) {
                clearInterval(id);
            } else {
                length++;
                elem.querySelector('circle').style.strokeDashoffset = length;
            }
        }
    }
});

//Swiper - The Approach
$(function () {
    const swiperWrapper = $('.swiper-approach-wrapper');
    var swiper;

    var approachButton = $('.approach-carousel-nav-button');

    if (swiperWrapper.length) {
        swiper = new Swiper('.swiper-approach', {
            speed: 500,
			autoplay: {
                delay: 1000
            },
            watchSlidesProgress: true,
            noSwipingClass: 'swiper-approach',
            breakpoints: {
                768: {
                    noSwipingClass: false,
                    autoHeight: true,
                }
            },
            navigation: {
                nextEl: '.approach-carousel .swiper-btn-next',
                prevEl: '.approach-carousel .swiper-btn-prev',
            },
            pagination: {
                el: '.swiper-approach-wrapper .swiper-pagination',
                clickable: true,
                type: 'bullets',
                dynamicBullets: false
            },
            on: {
                slideNextTransitionStart: function () {
                    approachButton.eq(swiper.activeIndex).addClass('active');
                },
                slidePrevTransitionStart: function () {
                    approachButton.eq(swiper.previousIndex).removeClass('active');
                }
            }
        });

        approachButton.on('click', function(){
            var index = ($(this).index() + 1);
            swiper.slideTo($(this).index());
            $(this).addClass('active');
            if (index >= 3) {
                $(this).prevAll().addClass('active');
            } else {
                $(this).nextAll().removeClass('active');
            }
        });
    }
});

//Keep <img> aspect ratio
$(function () {
    let element = document.querySelectorAll('.aspect-keeper');

    let image;

    for (let i = 0; i < element.length; i++) {
        image = new Image();
        image.src = element[i].querySelector('img').src;

        image.onload = function () {
            element[i].style.paddingBottom = 100 * (this.height / this.width) + '%';
            if (element[i].parentNode.classList.contains('img-loader')) {
                element[i].querySelector('img').style.width = element[i].parentNode.clientWidth + 'px';
            }
        }
    }
});

//Custom dropdown
$(function () {
    let $trigger = $('div[data-toggle="select-box"]'),
        $option = $('.select-box-custom-option');

    function toggleSelect() {
        let $this = $(this);

        $('.select-box-custom').not($this.closest('.select-box-custom')).removeClass('active');

        if (!$this.closest('.select-box-custom').hasClass('active')) {
            $this.closest('.select-box-custom').addClass('active');
        } else {
            $this.closest('.select-box-custom').removeClass('active');
        }
    }

    function selectOption(e) {
        e.preventDefault();
        let $this = $(this);

        if ($this.closest('.select-box-custom').find('.select-box-custom-selected').length) {
            $this.closest('.select-box-custom').find('.select-box-custom-selected').text($this.text());
        }

        //Update <select> value
        $this.closest('.select-box-custom').next().val($this.closest('.select-box-custom').find('.select-box-custom-dropdown').find('li').eq($this.index()).text());

        //Remove "active' class from custom select
        $('.select-box-custom').removeClass('active');
    }

    $(document).on('mouseup', function (e) {
        let container = $('.select-box-custom');

        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.removeClass('active');
        }
    });

    $(document).on('keydown', function (e) {
        let keyCode = e.keyCode || e.which;

        if (keyCode == 9) {
            if ($('.select-box-custom').hasClass('active')) {
                $('.select-box-custom').removeClass('active');
            }
        }
    });

    $(document).on('click', 'div[data-toggle="select-box"]', toggleSelect);
    $(document).on('click', '.select-box-custom-option', selectOption);
    $trigger.click(toggleSelect);
    $option.click(selectOption);
});

//Replace old select with custom dropdown
$(function () {
    let $field = $('select[class*="gfield_select"], select, .select');

    $field.each(function () {
        let $this = $(this);

        var placeholder = $this.data('placeholder') !== undefined ? $this.data('placeholder') : $this.find('option').first().text();

        console.log(placeholder);

        let $template = $('<div class="select-box-custom">' +
            '    <div class="select-box-custom-selected" data-toggle="select-box">' + placeholder + '</div>' +
            '    <ul class="select-box-custom-dropdown">' +
            '    </ul>' +
            '</div>');

        $template.insertBefore($this);
        $this.find('option').each(function () {
            let $thisOption = '<li class="select-box-custom-option">' + $(this).text() + '</li>';

            $this.prev().find('.select-box-custom-dropdown').append($thisOption);
        });

        if ($this.parent().parent().hasClass('dataTables_length')) {
            $('.select-box-custom-selected').text($(this).find('option').first().text());
        }
    });
});

//Modal
$(function () {
    const trigger = $('a[data-trigger="f-modal"], div[data-trigger="f-modal"], button[data-trigger="f-modal"], [data-trigger="f-modal"]');
    const close = $('.f-modal-close');
    const modal = $('.f-modal');
    const body = $('body');

    var a, b;

    function openModal(e) {
        e.preventDefault();

        var $this = $(this),
            target = $($this.attr('data-href'));

        body.removeClass('f-modal-is-open');
        modal.removeClass('f-modal-open');
        modal.hide();
        clearTimeout(b);

        if (!body.hasClass('f-modal-is-open')) {
            target.show();

            if (target.find('.js-player').length > 0) {
                target.find('.js-player')[0].play();
            }

            a = setTimeout(function () {
                body.addClass('f-modal-is-open');
                target.addClass('f-modal-open');
            }, 50);
        }
    }

    function closeModal() {
        var that = $(this);

        body.removeClass('f-modal-is-open');
        modal.removeClass('f-modal-open');

        if (that.closest('.f-modal').find('.js-player').length > 0) {
            that.closest('.f-modal').find('.js-player')[0].pause();
        }

        b = setTimeout(function () {
            modal.hide();
        }, 400);
    }

    trigger.on('click', openModal);
    close.on('click', closeModal);
});

//Project Modal
$(function(){
    var button = $('a[data-toggle="project-modal"]'),
        close = $('.project-modal-close'),
        body = $('body'),
        modal = $('.project-modal');

    var a;

    function openModal(e) {
        clearTimeout(a);

        if (!body.hasClass('modal-is-open')) {
            body.addClass('modal-is-open');
            modal.show();
            setTimeout(function(){
                modal.addClass('modal-open');
            }, 50);
        }
    }

    function closeModal(e) {
        body.removeClass('modal-is-open');
        modal.removeClass('modal-open');
        a = setTimeout(function(){
            modal.hide();
        }, 1050);
    }

    function initModal(e) {
        e.preventDefault();

        if (!body.hasClass('modal-is-open')) {
            openModal();
        } else {
            closeModal();
        }
    }

    button.on('click', initModal);
    close.on('click', closeModal);
});

//Truncate text
$(function(){
    var timelineRoadBlock = $('.timeline-road-block');

    function truncateText(item, truncateLength) {
        $.each(item, function () {
            var el = $(this),
                elP = el.find('p'),
                elPText = elP.text(),
                elPTextClone = elP.clone().text(),
                elPTextTrimmed = $.trim(elPText);

            if ($(window).innerWidth() >= 1024) {
                elP.html(elPTextTrimmed.substr(0, truncateLength) + '...');
            } else {
                elP.html(elP.data('text'));
            }

            elP.attr('data-text', elPTextClone);
        });
    }

    $(window).on('resize', function(){
        truncateText(timelineRoadBlock, 60);
    }).trigger('resize');
});

//Change timeline content
$(function(){
    var roadBlock = $('.timeline-road-block'),
        roadContent = $('.timeline-content');

    var a;

    function changeContent(e) {
        var that = $(this);

        clearTimeout(a);

        that.nextAll().removeClass('active');
        that.prevAll().addClass('active');
        that.addClass('active');

        roadContent.find('svg').css({
            opacity: '0',
            transform: 'scale(0.75)',
            transitionDelay: '',
            transitionTimingFunction: 'cubic-bezier(0.6, -1.5, 0.735, 0.0345)'
        });

        roadContent.find('h2').css({
            opacity: '0',
            transform: 'translate3d(0, -1rem, 0)',
            transitionDelay: 50 + 'ms',
        });

        roadContent.find('p').css({
            opacity: '0',
            transform: 'translate3d(0, -1rem, 0)',
            transitionDelay: 100 + 'ms',
        });

        a = setTimeout(function(){
            roadContent.find('.timeline-content-inner').find('svg use').attr('xlink:href', that.find('svg use').attr('xlink:href'));
            roadContent.find('h2').text(that.find('h2').text());
            roadContent.find('p').text(that.find('p').data('text'));

            roadContent.find('svg').css({
                opacity: '',
                transform: '',
                transitionTimingFunction: 'cubic-bezier(0.175, 0.885, 0.32, 2)'
            });

            roadContent.find('h2').css({
                opacity: '',
                transform: 'translate3d(0, 0, 0)',
            });

            roadContent.find('p').css({
                opacity: '',
                transform: 'translate3d(0, 0, 0)',
            });
        }, 700);
    }

    roadBlock.on('click', changeContent);
});

//Google Maps
function initialize() {
    var map_canvas = document.getElementById('googleMap');

    var map_options = {
        center: new google.maps.LatLng(40.768883, -73.963517),
        zoom: 12,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true
    };

    var image = 'img/pin.png';
    var map = new google.maps.Map(map_canvas, map_options);
    var locations = [
        ['Pain Free Dental Marketing', 40.768883, -73.963517, 1]
    ];

    var marker, i;

    for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i][1], locations[i][2]),
            map: map,
            icon: image
        });
    }

    var stylesLabels;

    stylesLabels = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ];

    map.setOptions({styles: stylesLabels});
    google.maps.event.trigger(map, 'resize');
}

if ($('#googleMap').length > 0) {
    google.maps.event.addDomListener(window, 'load', initialize);
}